<template>
  <div class="top">
    <el-breadcrumb separator="/" class="px-3 pb-0">
      <el-breadcrumb-item :to="{ path: '/ChargeMeTribe' }">首頁</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: `/ChargeMeTribe/TeacherIntroduce/${teacherInfo.id}` }"
        >老師介紹</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{ path: '/Search' }"
        v-if="prevRoute.path == '/Search'"
        >搜尋</el-breadcrumb-item
      >
      <el-breadcrumb-item>課程資訊</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="d-flex mx-auto containerCust">
      <div class="brief">
        <div class="area brief_info">
          <div class="coverArea">
            <h2 class="name">{{ courseData.name }}</h2>
            <hr />
            <div class="coursePriceInfo">
              <el-image
                class="courseCover"
                :src="courseData.cover"
                fit="contain"
              ></el-image>
              <div class="priceIntroduction">
                <p>
                  <span class="priceItem">教學樂器</span>
                  <span class="slash">/</span>
                  {{ courseData.instrumentName }}
                </p>
                <p>
                  <span class="priceItem">風格</span>
                  <span class="slash">/</span
                  >{{
                    courseData.instrumentType == "WesternMusic"
                      ? "西樂"
                      : courseData.instrumentType == "ChineseMusic"
                      ? "中樂"
                      : "其他"
                  }}
                </p>
                <p>
                  <span class="priceItem">課程等級</span>
                  <span class="slash">/</span>
                  {{ getDegree(courseData.degree) }}
                </p>
                <p>
                  <span class="priceItem">授課方式</span>
                  <span class="slash">/</span>
                  {{ courseData.teachingTypes }}
                </p>
                <p>
                  <span class="priceItem">價格</span>
                  <span class="slash">/</span>
                  $NT {{ courseData.price }} 堂
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="area introVideo">
          <h2 class="name">課程影片介紹</h2>
          <iframe
            :src="courseData.introVideo"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div class="area teacherInfo" v-if="$store.getters.mobileSize">
          <h2 class="name">授課老師</h2>
          <div class="teacherInfos">
            <el-avatar
              size="large"
              :src="teacherInfo.avatar"
              style="width: 120px"
              shape="square"
            ></el-avatar>
            <div class="px-2">
              <h3 class="teacherName">{{ teacherInfo.teacherName }}</h3>
              <div class="teacherBrief">{{ teacherInfo.brief }}</div>
            </div>
          </div>
          <!-- <el-link
            class="mt-2 goTeacherInfo"
            href="https://element.eleme.io"
            type="primary"
            >點此查看老師資訊<i class="ms-1 fas fa-angle-double-right"></i
          ></el-link> -->
        </div>

        <div class="area otherinfo">
          <h2 class="name">課程簡介</h2>
          <div class="otherinfos">
            <h3 class="introductionTitle">簡介</h3>
            <div class="introductionInfo">
              {{ courseData.introduction }}
            </div>
            <h3 class="introductionTitle">說明</h3>
            <div class="introductionInfo" v-html="courseData.detail"></div>
          </div>
        </div>

        <div class="area otherinfo">
          <VueCal :teacherId="courseData.teacherId" :courseId="courseData.id" />
        </div>
      </div>

      <div class="teacherCol" v-if="!$store.getters.mobileSize">
        <div class="teacherRight">
          <div class="area teacherInfo">
            <h2 class="name mb-2">授課老師</h2>
            <div class="text-left">
              <el-avatar :size="80" :src="teacherInfo.avatar"></el-avatar>
              <div class="px-2">
                <h3 class="fs-5 fw-bold mb-2">{{ teacherInfo.teacherName }}</h3>
                <div class="" v-html="teacherInfo.brief"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCal from "../../components/ChargeMeTribe/TeacherIntroduce/TeacherCal.vue";
export default {
  data() {
    return {
      activeName: "ClassInfo",
      courseData: {
        name: "",
        introVideo: "",
        price: 0,
        introduction: "",
      },
      courseDetail: "",
      teacherInfo: {},
      prevRoute: "",
    };
  },
  components: {
    VueCal,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async created() {
    await this.initData();
  },
  methods: {
    sendMessage() {
      this.$router.push("/ChargeMeTribe/Message");
    },
    async initData() {
      const vm = this;
      await vm
        .fetchAPI(
          "get",
          `${process.env.VUE_APP_URL_API}/Courses/${vm.$route.params.id}`
        )
        .then((res) => {
          vm.courseData = {
            id: res.data.id,
            name: res.data.name,
            cover: res.data.cover,
            introVideo: res.data.introVideo.includes("https")
              ? vm.checkVideo(res.data.introVideo)
              : vm.$store.state.default.video,
            introduction: res.data.introduction,
            instrumentId: res.data.instrumentId,
            instrumentType: res.data.instrumentType,
            instrumentName: res.data.instrumentName,
            status: res.data.status,
            teacherId: res.data.teacherId,
            memberId: res.data.memberId,
            teacherName: res.data.teacherName,
            price: res.data.price,
            degree: res.data.degree,
            detail: res.data.detail,
            targetAudience: res.data.targetAudience,
            teacherRule: res.data.teacherRule,
            payPeriod: res.data.payPeriod,
            payMethod: res.data.payMethod,
            teachingTypes: res.data.teachingTypes,
            regions: res.data.regions,
          };
          vm.courseDetail = res.data.detail;
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      await vm
        .fetchAPI(
          "get",
          `${process.env.VUE_APP_URL_API}/teacher/${vm.courseData.memberId}/info`
        )
        .then((res) => {
          vm.teacherInfo = {
            teacherName: res.data.nickName,
            avatar: vm.imageProcess(res.data.avatar),
            brief: res.data.simpleIntroduction,
            id: res.data.memberId,
            socialMedia:
              res.data.socialMedia == ""
                ? { fb: "", ig: "", twitter: "" }
                : JSON.parse(res.data.socialMedia),
          };
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  position: relative;
  margin: auto;
  text-align: start;
  width: 100%;
  list-style-type: circle !important;
  & > div {
    margin: 2rem 0;
  }
  // background-image: linear-gradient(180deg, $main_color, #e7d39a);
  // color: rgba(255, 255, 255, 0.849);
  text-align: start;
  .el-breadcrumb {
    padding: 1.5rem 0;
    font-size: 1rem;
    background-color: white;
  }
  .brief {
    .brief_info {
      .coverArea {
        // border: 1px solid black;
        padding: 0 1rem 1rem 1rem;
        background-color: white;
        .name {
          color: black;
          font-size: 2rem;
          margin-bottom: 0.5rem;
        }
      }

      .brief_introduce {
        margin: 1rem 0;
        line-height: 1.5rem;
        flex-grow: 1;
      }
      .price {
        strong {
          font-weight: 700;
          font-size: 2rem;
          color: #409eff;
        }
      }
    }
    .introVideo {
      padding: 0 1rem 1rem 1rem;
      background-color: white;
      .name {
        color: black;
        font-weight: 700;
        font-size: 1rem;
      }
      iframe {
        width: 100%;
        height: 35vh;
        margin: 1rem 0;
      }
    }
    .teacherInfo {
      padding: 0 1rem 1rem 1rem;
      background-color: white;
      .goTeacherInfo {
        font-size: 10px;
        font-weight: 700;
      }
      .name {
        color: black;
        font-weight: 700;
        font-size: 1rem;
      }
      .teacherInfos {
        padding: 0.5rem 0 0 0;
        display: flex;
        .teacherName {
          font-weight: 700;
          margin-bottom: 0.3rem;
        }
        .teacherBrief {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    .priceIntroduction {
      padding: 1rem 0rem;
      background-color: white;
      flex-wrap: wrap;
      font-size: 1rem;
      p {
        margin-bottom: 0.5rem;
        .slash {
          color: #ff9900;
          margin-left: 0.3rem;
          margin-right: 0.3rem;
        }
        .priceItem {
          color: black;
        }
      }
    }
    .otherinfo {
      padding: 0 1rem 1rem 1rem;
      background-color: white;
      .name {
        color: black;
        font-weight: 700;
        font-size: 1rem;
      }
      .otherinfos {
        padding-top: 1rem;
        .introductionTitle {
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px solid $main_color;
          padding-bottom: 0.5rem;
        }
        .introductionInfo {
          padding-bottom: 0.5rem;
          padding-top: 0.5rem;
        }
      }
    }

    .mix {
      display: flex;
      justify-content: space-between;
    }
  }
}
.el-tabs {
  padding: 1rem 2rem;
}

@media screen and (min-width: 768px) {
  .coursePriceInfo {
    display: flex;
    .courseCover {
      width: 50%;
    }
  }
  .priceIntroduction {
    padding: 0.5rem 1rem !important;
  }
  .top {
    width: 75vw;
  }
  .teacherCol {
    padding: 1rem 0;
    width: 29%;

    .teacherRight {
      padding: 1rem;
      background-color: white;
      .name {
        color: black;
        font-size: 1rem;
      }
    }
  }
  .brief {
    width: 60%;
    flex-wrap: wrap;
    // justify-content: space-between;
    .area {
      width: 100%;
    }
  }
}
</style>
